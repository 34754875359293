'use client';

import React, { useState } from 'react';
import clsx from 'clsx';
import { MenuItemType } from '@akinon/next/types';
import { useAppDispatch, useAppSelector } from '@akinon/next/redux/hooks';
import { closeMobileMenu } from '@akinon/next/redux/reducers/header';
import { Image } from '@akinon/next/components/image';

import { UserMenu } from './user-menu';
import { Icon, Link } from 'components';
import HamburgerMenuNewTag from 'components/hamburger-menu-new-tag';
import HamburgerSearch from './hamburger-search';
import { useGetMenuQuery } from '@akinon/next/data/client/misc';
import { menuGenerator } from '@akinon/next/utils';
import dynamic from 'next/dynamic';

interface MobileMenuProps {
  menu: MenuItemType[];
}

const MobileSubMenu = dynamic(() => import('./mobile-sub-menu'), {
  ssr: false
});

export default function MobileMenu(props: MobileMenuProps) {
  const { menu } = props;
  let allMenuData = menu;

  const { data: menuData } = useGetMenuQuery(4);
  const allMenu = menuGenerator(menuData ?? []);
  const isMobileMenuOpen = useAppSelector(
    (state) => state.header.isMobileMenuOpen
  );
  allMenuData = isMobileMenuOpen && allMenu.length !== 0 ? allMenu : menu;

  const dispatch = useAppDispatch();

  const [selectedSubMenu, setSelectedSubMenu] = useState(null);
  const [selectedLastSubMenu, setSelectedLastSubMenu] = useState(null);

  return (
    <>
      {/* MENU OVERLAY */}
      <div
        className={clsx(
          'fixed top-0 left-0 z-[100] w-screen h-screen invisible opacity-0 bg-black bg-opacity-80 transition duration-500',
          {
            '!visible !opacity-100 scroll-lock': isMobileMenuOpen
          }
        )}
        // TODO: Remove this after we have a better solution for clicking outside of the menu
        onClick={() => {
          dispatch(closeMobileMenu());
          setSelectedSubMenu(null);
          setSelectedLastSubMenu(null);
        }}
      />
      {/* TODO: Add a way to close the menu when clicking outside of it */}
      {/* MENU FIRST LAYER */}
      <div
        className={clsx(
          'fixed top-0 left-0 z-[100] flex flex-col bg-white w-[326px] h-screen',
          'invisible opacity-0 transition duration-500 transform -translate-x-72',
          {
            '!visible !opacity-100 translate-x-0': isMobileMenuOpen
          },
          { 'pt-3': selectedSubMenu === null }
        )}
      >
        {selectedSubMenu === null && (
          <>
            <UserMenu isMobile />
            <HamburgerSearch />
          </>
        )}
        <div className="relative flex-1 overflow-x-hidden">
          <ul
            className={clsx('pt-2 transition duration-500 transform', {
              '-translate-x-full': selectedSubMenu
            })}
          >
            {allMenuData
              .filter(
                (m) =>
                  !m.extra_context.attributes?.visibility ||
                  m.extra_context.attributes?.visibility?.value ==
                    'onlymobile' ||
                  m.extra_context.attributes?.visibility?.value == 'all'
              )
              .map(
                (item, index) =>
                  item.label != null && (
                    <li
                      key={index}
                      className="ms-[20px] me-[20px] py-[19px] border-b border-b-gray-300 last:mb-10"
                    >
                      <Link
                        href={item.url}
                        onClick={(e) => {
                          if (item.children.length > 0) {
                            e.preventDefault();
                            setSelectedSubMenu(item);
                          }
                        }}
                        className="flex items-center justify-between ms-[0.5px] me-[0.5px]"
                      >
                        <div className="flex gap-[30px]">
                          <div className="flex items-center gap-[19px]">
                            <Image
                              width={
                                parseInt(
                                  item?.extra_context?.attributes?.mobile_logo
                                    ?.value?.width
                                ) || 22
                              }
                              height={
                                parseInt(
                                  item?.extra_context?.attributes?.mobile_logo
                                    ?.value?.width
                                ) || 22
                              }
                              alt={item?.label}
                              src={
                                item?.extra_context?.attributes?.mobile_logo
                                  ?.kwargs?.value?.image?.url
                              }
                            />
                            <div>
                              <span className="uppercase font-serif text-sm text-black-400 opacity-90 leading-none">
                                {item.label}
                              </span>
                            </div>
                          </div>
                          {item?.extra_context?.attributes?.new?.value ===
                            'true' && <HamburgerMenuNewTag />}
                        </div>
                        {item.children.length > 0 && (
                          <Icon
                            name="chevron-end"
                            size={15}
                            className="text-gray-650 rtl:rotate-180"
                          />
                        )}
                      </Link>
                    </li>
                  )
              )}
          </ul>

          {/* MENU SECOND LAYER */}
          <MobileSubMenu
            selectedSubMenu={selectedSubMenu}
            setSelectedSubMenu={setSelectedSubMenu}
            selectedLastSubMenu={selectedLastSubMenu}
            setSelectedLastSubMenu={setSelectedLastSubMenu}
            isMobileMenuOpen={isMobileMenuOpen}
          />
        </div>
      </div>
    </>
  );
}
