'use client';

import { useAppDispatch, useAppSelector } from '@akinon/next/redux/hooks';
import { setOpenedMenu } from '@akinon/next/redux/reducers/header';
import clsx from 'clsx';

import { Link } from 'components';
import Search from './search';
import { useLocalization } from '@akinon/next/hooks';
import { useEffect, useState } from 'react';
import { useGetMenuQuery } from '@akinon/next/data/client/misc';
import { menuGenerator } from '@akinon/next/utils';
import dynamic from 'next/dynamic';

const SubMenu = dynamic(() => import('./sub-menu'), {
  ssr: false
});

export default function Navbar(props) {
  const { menu } = props;
  const [allmenuData, setAllMenuData] = useState(menu);

  const { data: menuData } = useGetMenuQuery(4);
  const allMenu = menuGenerator(menuData ?? []);
  const [allChildMenu, setAllChildMenu] = useState(false);

  const dispatch = useAppDispatch();
  const { isSearchOpen, openedMenu } = useAppSelector((state) => state.header);
  const { t } = useLocalization();

  useEffect(() => {
    if (allmenuData.length === 0) {
      setAllMenuData(menu);
    }

    if (allChildMenu) {
      setAllMenuData(allMenu);
    }
  }, [allChildMenu, menu]);

  return (
    <>
      <nav className="header-grid-area-nav relative hidden flex-wrap items-center justify-center justify-items-center lg:flex">
        <ul className="flex flex-wrap items-center justify-center justify-items-center gap-x-8 gap-y-4 lg:flex-1 lg:border-t">
          {allmenuData
            .filter(
              (m) =>
                !m.extra_context.attributes?.visibility ||
                m.extra_context.attributes?.visibility?.value ==
                  'onlydesktop' ||
                m.extra_context.attributes?.visibility?.value == 'all'
            )
            .map(
              (item, index) =>
                item.label != null && (
                  <li
                    key={index}
                    onMouseEnter={() => {
                      dispatch(setOpenedMenu(item.pk));
                      setAllChildMenu(true);
                    }}
                    onMouseLeave={() => {
                      dispatch(setOpenedMenu(null));
                    }}
                    className="group"
                  >
                    <Link
                      href={item.url}
                      className={clsx(
                        'relative flex items-center justify-center px-1 pt-6 pb-5 text-[11px] uppercase leading-none transition',
                        'after:content-[""] after:absolute after:block after:w-full after:h-0.5 after:bottom-0 after:left-0 after:right-0',
                        'after:bg-secondary after:z-50 after:invisible after:transition-all group-hover:after:visible font-serif'
                      )}
                      data-testid={`navbar-categories-${item.pk}`}
                    >
                      <span>{item.label}</span>
                      {item.extra_context?.attributes?.new?.value ===
                        'true' && (
                        <div
                          className={clsx(
                            'flex justify-center absolute font-serif',
                            'bg-secondary top-0.5 text-[10px] px-2 py-0.5 rounded-sm text-white capitalize'
                          )}
                        >
                          <span className="pb-0.5">
                            {t('common.header.new')}
                          </span>
                          <div className="absolute w-5 -bottom-1 overflow-hidden flex justify-center">
                            <div className="w-1 h-1 bg-secondary -rotate-45 transform origin-top-left"></div>
                          </div>
                        </div>
                      )}
                    </Link>

                    {allChildMenu && (
                      <SubMenu
                        items={item.children}
                        parentItem={item}
                        openedMenu={openedMenu}
                      />
                    )}
                  </li>
                )
            )}
        </ul>
      </nav>
      {isSearchOpen && <Search />}
    </>
  );
}
