'use client';

import clsx from 'clsx';
import { useLocalization } from '@akinon/next/hooks';

export default function HamburgerMenuNewTag() {
  const { t } = useLocalization();

  return (
    <div className="flex items-center">
      <div
        className="bg-transparent
        border-t-[3px] border-t-transparent
        border-r-[3.5px] border-r-secondary
        border-b-[3px] border-b-transparent
        rounded-l-[100%]
        "
      ></div>
      <div
        className={clsx(
          'font-serif text-[13px] text-white',
          'bg-secondary h-[18px] pl-1 pr-0.5 border border-transparent rounded'
        )}
      >
        {t('common.header.new')}
      </div>
    </div>
  );
}
