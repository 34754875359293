'use client'

import { useEffect, useState, useRef} from 'react';
import { Image } from '@akinon/next/components/image';
import clsx from 'clsx';

export default function HeaderLogo() {

  const [isScrolled, setIsScrolled] = useState(false);
  // let isScrolled;
  const scrollTimout = useRef(null);

  useEffect(() => {
    window.addEventListener('scroll', checkScrollHeight);
    return () => window.removeEventListener('scroll', checkScrollHeight);
  }, []);

  const checkScrollHeight = () => {
      // debounce added to prevent multiple assignments at breakpoint and flickering problem
      clearTimeout(scrollTimout.current)
      scrollTimout.current = setTimeout(function () {
        const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
        if (winScroll >= 152) {
          setIsScrolled(true);
          // second control added (breakpoint - (half logo height + negative margin) ) 
          // for logo size reduce effects scrollY position
        } else if ( winScroll <= 84) {
          setIsScrolled(false);
        }
      }, 50);
  };

  return (
    <>
      <Image
        src="/logo.svg"
        width={155}
        height={32}
        className="lg:hidden object-cover"
        alt="logo"
      />
      <div className={clsx('hidden lg:block object-cover transition-all',
            isScrolled ? "w-[80px] mt-[-15px]" : "w-[160px] mt-0"
          )}>
        <Image
          src="/logo-desktop.svg"
          width={160}
          height={105}
          alt="logo"
        />
      </div>
    </>
  );
};
