'use client';

import { useAppDispatch } from '@akinon/next/redux/hooks';
import { openSearch } from '@akinon/next/redux/reducers/header';
import clsx from 'clsx';

import { Icon } from 'components';

export default function MobileSearchButton() {
  const dispatch = useAppDispatch();

  return (
    <div className="flex">
      <div className={clsx('relative flex items-center')}>
        <button
          onClick={() => dispatch(openSearch())}
          data-testid="header-search"
        >
          <Icon name="search" size={16} />
        </button>
      </div>
    </div>
  );
}
