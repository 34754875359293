'use client';

import { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@akinon/next/redux/hooks';
import { closeSearch } from '@akinon/next/redux/reducers/header';
import clsx from 'clsx';

import { Icon } from 'components';
import Results from './results';
import { ROUTES } from 'routes';
import { useRouter, useLocalization } from '@akinon/next/hooks';

export default function Search() {
  const { t } = useLocalization();
  const router = useRouter();
  const dispatch = useAppDispatch();
  const isSearchOpen = useAppSelector((state) => state.header.isSearchOpen);
  const [searchText, setSearchText] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isSearchOpen) {
      inputRef.current?.focus();
    }
  }, [isSearchOpen]);

  return (
    <>
      <div
        className={clsx(
          // 177px is the height of the header
          'absolute bg-gray-20 w-screen h-screen transition duration-500 left-0 -bottom-0.5 translate-y-full z-30',
          isSearchOpen && searchText
            ? 'visible opacity-100'
            : 'invisible opacity-0'
        )}
        role="button"
        onClick={() => dispatch(closeSearch())}
      />
      <div
        className={clsx(
          'absolute h-full min-h-[83px] lg:h-auto bg-gray-20 p-4 pr-4 lg:p-6 left-0 -bottom-[82px] border-t border-gray-650 lg:bottom-[57px]',
          'lg:translate-y-full z-40 w-screen transition duration-500 lg:border-none',
          isSearchOpen ? 'visible opacity-100' : 'invisible opacity-0'
        )}
      >
        <div className="relative lg:container mx-auto flex flex-col">
          <div className="flex items-center space-x-3 rtl:space-x-reverse">
            <div className="relative w-full h-[50px] lg:h-[55px]">
              <input
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && searchText.trim() !== '') {
                    router.push(`${ROUTES.LIST}/?search_text=${searchText}`);
                  }
                }}
                className={clsx(
                  'w-full h-full border border-gray text-sm font-sans',
                  'px-5 py-6 outline-none text-xs text-black-400 uppercase',
                  'placeholder:text-black-400 placeholder placeholder:text-xs'
                )}
                placeholder={t('common.search.placeholder')}
                ref={inputRef}
              />
              <Icon
                name="search"
                size={18}
                className="absolute right-4 rtl:left-4 rtl:right-[unset] bottom-4 lg:bottom-5 text-gray-650"
              />
            </div>
            <Icon
              name="close"
              size={18}
              onClick={() => dispatch(closeSearch())}
              className="2xl:absolute 2xl:-right-8 rtl:2xl:-left-8 rtl:right-[unset] cursor-pointer"
            />
          </div>
          <Results searchText={searchText} />
        </div>
      </div>
    </>
  );
}
