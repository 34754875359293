'use client';

import { useMemo } from 'react';
import { useAutocompleteQuery } from '@akinon/next/data/client/misc';
import { ROUTES } from 'routes';

import { Image, LoaderSpinner, Price, Link, Icon } from 'components';
import { useDebounce, useLocalization } from '@akinon/next/hooks';
import clsx from 'clsx';

interface ResultsProps {
  searchText: string;
}

const MINIMUM_SEARCH_LENGTH = 3;

export default function Results(props: ResultsProps) {
  const { searchText } = props;
  const { t } = useLocalization();
  const debouncedSearchText = useDebounce(searchText, 400);
  const { currentData, isFetching, isLoading } = useAutocompleteQuery(
    debouncedSearchText,
    {
      refetchOnMountOrArgChange: true,
      skip: debouncedSearchText.length < MINIMUM_SEARCH_LENGTH
    }
  );

  const products = useMemo(
    () =>
      currentData?.groups.find((group) => group.suggestion_type === 'Product')
        ?.entries ?? [],
    [currentData]
  );

  if (
    debouncedSearchText.length < MINIMUM_SEARCH_LENGTH ||
    searchText !== debouncedSearchText
  ) {
    return null;
  }

  if (isLoading || isFetching) {
    return (
      <div className="my-2">
        <LoaderSpinner />
      </div>
    );
  }

  const ProductButtonText = () => {
    if (products.length === 0) {
      return t('common.search.no_product_result');
    } else {
      return t('common.search.view_all');
    }
  };

  return (
    <div
      className={clsx(
        `w-full flex flex-col-reverse lg:flex-row bg-white px-6 lg:px-8 pt-4 pb-10 lg:pt-7 mt-3 ${
          products.length === 0
            ? 'lg:pb-[410px]'
            : 'lg:max-h-[390px] lg:overflow-y-scroll'
        }`,
        'border border border-gray shadow-2xl lg:space-x-28 rtl:space-x-reverse'
      )}
    >
      <div className="w-full space-y-4">
        <h2 className="text-black-400 font-medium text-[25px]">
          <span
            className={`${
              products.length === 0 ? 'lg:border-b border-primary pb-1' : null
            }`}
          >
            {t('common.search.search_suggestion')}
          </span>
        </h2>
        <div className="grid grid-cols-2 sm:grid-cols-4 gap-y-4 gap-x-2 lg:gap-2">
          {products.map((product, index) => (
            <Link
              href={product.url}
              key={index}
              className="bg-gray-20 font-sans"
            >
              <Image
                src={product.extra.image}
                alt={product.label}
                className="w-full h-[150px] lg:h-[250px]"
              />
              <div className="flex flex-col p-2 lg:p-3.5 space-y-6">
                <span className="h-4 lg:h-10 line-clamp-1 lg:line-clamp-2 text-primary text-sm font-medium">
                  {product.label}
                </span>
                <Price
                  value={product.extra.price}
                  className="text-black-400 text-sm uppercase"
                />
              </div>
            </Link>
          ))}
        </div>

        {products.length === 0 && (
          <div className="w-full h-12 p-3 flex content-center bg-[#fdf0d6] text-[#c07600] mb-24">
            <Icon name="warning" size={24} className="mr-[13.5px]" />
            <p className="font-sans text-[#6f4400] text-[14px] mt-1">
              {t('common.search.no_results')}
            </p>
          </div>
        )}
        <Link
          href={{
            pathname: ROUTES.LIST,
            query: { search_text: debouncedSearchText }
          }}
          data-testid="search-view-all"
          className={clsx(
            'w-full h-[50px] lg:h-[55px] font-sans uppercase',
            'flex items-center justify-center bg-primary text-white text-sm transition-all hover:bg-secondary'
          )}
        >
          {ProductButtonText()}
        </Link>
      </div>
    </div>
  );
}
