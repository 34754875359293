'use client';

import clsx from 'clsx';
import { useRouter, useLocalization } from '@akinon/next/hooks';
import { Icon, Input } from '../../components';
import { useState } from 'react';

export default function HamburgerSearch() {
  const { t } = useLocalization();
  const router = useRouter();

  const [input, setInput] = useState('');

  return (
    <div className="ms-[19px] me-[22px] relative">
      <Icon
        name="search"
        size={16}
        className={clsx(
          'absolute right-2.5 rtl:right-auto rtl:left-2.5 top-1/2 -translate-y-1/2 z-10 text-gray-650',
          'hover:text-black-400'
        )}
        onClick={() => {
          router.push(input);
        }}
      />
      <Input
        className={clsx(
          'font-serif text-xs leading-[11px] border-gray-300 bg-gray-23 text-gray-650 px-3',
          'hover:border-gray-300',
          'focus:text-black-400 focus:border-gray-300'
        )}
        placeholder={t('common.header.search_text')}
        onChange={(e) => {
          const element = e.currentTarget as HTMLInputElement;
          setInput(`list?search_text=${element.value}`);
        }}
      />
    </div>
  );
}
