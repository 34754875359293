'use client';

import { Link, Icon } from 'components';
import { useSession } from 'next-auth/react';
import { ROUTES } from 'routes';
import clsx from 'clsx';

import { closeMobileMenu } from '@akinon/next/redux/reducers/header';
import { useAppDispatch } from '@akinon/next/redux/hooks';
import { useLocalization } from '@akinon/next/hooks';

interface UserMenuProps {
  isMobile: boolean;
}

export const UserMenu = (props: UserMenuProps) => {
  const { isMobile } = props;
  const { data: session, status } = useSession();
  const { t } = useLocalization();
  const dispatch = useAppDispatch();

  const MenuItems = [
    {
      url: ROUTES.LOGIN,
      label: `${
        isMobile
          ? `${t('common.header.login')}/${t('common.header.register')}`
          : t('common.header.account')
      }`,
      dataTestId: `header-login${isMobile ? '-mobile' : ''}`
    }
  ];

  return (
    <ul
      className={clsx(
        'items-center divide-x divide-black',
        isMobile ? 'mx-4 flex pb-5 pt-2.5 text-sm' : 'hidden lg:flex'
      )}
      id="user-menu"
    >
      {status === 'authenticated' ? (
        <li className={clsx('flex items-center gap-3')}>
          {isMobile && (
            <Icon name="logo-mobile" className="text-[#d1aa67]" size={36} />
          )}
          <div>
            <div
              className={clsx(
                'font-sans uppercase flex',
                isMobile ? 'text-black-400 leading-normal' : ''
              )}
            >
              {`${t('common.header.welcome')}, ${session.user.firstName} ${
                session.user.lastName
              }!`}
            </div>

            <Link
              href={ROUTES.ACCOUNT}
              data-testid={`header-user${isMobile ? '-mobile' : ''}`}
              className={clsx(
                'font-sans',
                isMobile ? 'text-black-400 leading-[8px]' : ''
              )}
              onClick={() => dispatch(closeMobileMenu())}
            >
              <div className="text-black-400 underline">
                {t('common.header.my_account')}
              </div>
            </Link>
          </div>
        </li>
      ) : (
        MenuItems.map((link, index) => (
          <li
            key={index}
            className={clsx(
              'flex items-center uppercase w-full',
              isMobile ? '' : 'px-2'
            )}
          >
            {isMobile && (
              <Icon name="logo-mobile" className="text-[#d1aa67]" size={36} />
            )}
            <Link
              href={link.url}
              passHref={true}
              data-testid={link.dataTestId}
              className={clsx(
                'font-sans uppercase flex',
                isMobile
                  ? 'w-full justify-center text-sm text-black-400 pr-7'
                  : ''
              )}
            >
              {link.label}
            </Link>
          </li>
        ))
      )}
    </ul>
  );
};
